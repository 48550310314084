import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { FormItem } from '@components/forms';
import BookingOrderDetailsInfo from '../../components/detailsInfo';
import { bookingOrderDetailsBookingOrderSelector } from '../store/selectors';
import Splitter from '@components/common/splitter';
import BookingOrderCSPInfo from '@features/bookingOrder/components/cspInfo';
import useTechConfig from '@/presentation/hooks/useTechConfig';
import { EAppFeature } from '@/presentation/types';

export const BookingOrderDetailsDetailsInfoAdapter = () => {
  const bookingOrder = useSelector(bookingOrderDetailsBookingOrderSelector);
  const { hasFeature } = useTechConfig();

  if (!bookingOrder) {
    return null;
  }

  return (
    <Grid
      item
      xs={4}
    >
      <FormItem title='Сведения о заказе'>
        <BookingOrderDetailsInfo
          date={bookingOrder.createdAt}
          customer={bookingOrder.customer}
          comment={bookingOrder.customerComment}
        />
      </FormItem>
      {hasFeature(EAppFeature.Csp) && bookingOrder.hasRzdSocialPackage && bookingOrder.customerRzdSocialPackage && (
        <>
          <Splitter size={4} />
          <FormItem title='Компенсируемый социальный пакет работника ОАО «РЖД»'>
            <BookingOrderCSPInfo socialPackage={bookingOrder.customerRzdSocialPackage} />
          </FormItem>
        </>
      )}
    </Grid>
  );
};
